import { css } from "@emotion/css";
import { Grid} from "@mui/material";
import { IOrderProofOfDeliveryProps } from "components/interfaces";
import ModalImage from "react-modal-image";

export const OrderProofOfDelivery: React.FC<IOrderProofOfDeliveryProps> = (
  props: IOrderProofOfDeliveryProps
) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ModalImage
          small={props.order.proofOfDeliveryFileUrl!}
          large={props.order.proofOfDeliveryFileUrl!}
          hideDownload={true}
          hideZoom={true}
          className={css`
            max-height: 200px !important;
          `}
        />
      </Grid>
    </Grid>
  );
};
